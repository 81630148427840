<template>
  <div class="d-flex flex-column flex-root">
    <div
      class="
        login login-1
        d-flex
        flex-column flex-lg-row flex-column-full-fluid
        bg-white
      "
      :class="{
        'login-signin-on': this.state == 'signin',
        'login-signup-on': this.state == 'signup',
        'login-forgot-on': this.state == 'forgot'
      }"
      id="kt_login"
    >
      <!--begin::Aside-->
      <div
        class="login-aside d-flex flex-column flex-row-auto"
        style="background-color: #361447"
      >
        <div class="d-flex flex-column-auto flex-column pt-lg-40 pt-15">
          <h3
            class="font-weight-bolder text-center font-size-h4 font-size-h1-lg"
            style="color: white"
          >
            MiraclePay NL
            <!-- <br /> -->
            <!-- {{ $t("SLOGAN") }} -->
          </h3>

          <h3
            class="font-weight-bolder text-center font-size-h4 font-size-h1-lg"
            style="color: white"
          >
            Admin Panel
          </h3>
        </div>
        <div
          class="
            aside-img
            d-flex
            flex-row-fluid
            bgi-no-repeat bgi-position-y-bottom bgi-position-x-center
          "
          :style="{ backgroundImage: `url(${backgroundImage})` }"
        ></div>
      </div>
      <!--begin::Aside-->
      <!--begin::Content-->
      <div
        class="
          login-content
          flex-row-fluid
          d-flex
          flex-column
          justify-content-center
          position-relative
          overflow-hidden
          p-7
          mx-auto
        "
      >
        <div class="d-flex flex-column-fluid flex-center">
          <!--begin::Signin-->
          <div class="login-form login-signin">
            <form
              class="form"
              novalidate="novalidate"
              id="kt_login_signin_form"
            >
              <div class="pb-13 pt-lg-0 pt-5">
                <h3
                  class="
                    font-weight-bolder
                    text-dark
                    font-size-h4 font-size-h1-lg
                  "
                >
                  {{ $t("AUTH.LOGIN.WELCOME") }}
                </h3>
                <span class="text-muted font-weight-bold font-size-h4 d-none">
                  <!-- New Here? -->
                  {{ $t("AUTH.LOGIN.NEW_HERE") }}
                  <a
                    id="kt_login_signup"
                    class="text-primary font-weight-bolder"
                    @click="showForm('signup')"
                  >
                    {{ $t("AUTH.LOGIN.CREATE_ACCOUNT") }}
                  </a>
                </span>
              </div>
              <div class="form-group">
                <label class="font-size-h6 font-weight-bolder text-dark">{{
                  $t("FORM.PHONE_OR_EMAIL")
                }}</label>
                <div
                  id="example-input-group-1"
                  label
                  label-for="example-input-1"
                >
                  <input
                    class="
                      form-control form-control-solid
                      h-auto
                      py-7
                      px-6
                      rounded-lg
                    "
                    type="text"
                    name="identifier"
                    ref="identifier"
                    v-model="form.identifier"
                  />
                </div>
              </div>
              <div class="form-group">
                <div class="d-flex justify-content-between mt-n5">
                  <label
                    class="font-size-h6 font-weight-bolder text-dark pt-5"
                    >{{ $t("FORM.PASSWORD") }}</label
                  >
                  <a
                    class="
                      text-primary
                      font-size-h6 font-weight-bolder
                      text-hover-primary
                      pt-5
                      d-none
                    "
                    id="kt_login_forgot"
                    @click="showForm('forgot')"
                    >{{ $t("AUTH.FORGOT.TITLE") }}</a
                  >
                </div>
                <div
                  id="example-input-group-2"
                  label
                  label-for="example-input-2"
                >
                  <input
                    class="
                      form-control form-control-solid
                      h-auto
                      py-7
                      px-6
                      rounded-lg
                    "
                    type="password"
                    name="password"
                    ref="password"
                    v-model="form.password"
                    autocomplete="off"
                  />
                  <p
                    class="text-right text-primary cursor-pointer"
                    @click="redirectForgotPassword()"
                  >
                    {{ $t("AUTH.GENERAL.FORGOT_BUTTON") }}
                  </p>
                </div>
              </div>
              <div class="pb-lg-0 pb-5">
                <button
                  ref="kt_login_signin_submit"
                  class="
                    btn btn-primary
                    font-weight-bolder font-size-h6
                    px-15
                    py-4
                    my-3
                    mr-3
                    w-50
                  "
                >
                  {{ $t("AUTH.LOGIN.LOGIN") }}
                </button>
              </div>
            </form>
          </div>
          <!--end::Signin-->
          <!--begin::Signup-->
          <div class="login-form login-signup">
            <form
              class="form"
              novalidate="novalidate"
              id="kt_login_signup_form"
            >
              <div class="pb-13 pt-lg-0 pt-5">
                <h3
                  class="
                    font-weight-bolder
                    text-dark
                    font-size-h4 font-size-h1-lg
                  "
                >
                  {{ $t("AUTH.REGISTER.TITLE") }}
                </h3>
                <p class="text-muted font-weight-bold font-size-h4">
                  {{ $t("AUTH.REGISTER.DESC") }}
                </p>
              </div>
              <div class="form-group">
                <input
                  class="
                    form-control form-control-solid
                    h-auto
                    py-7
                    px-6
                    rounded-lg
                    font-size-h6
                  "
                  type="text"
                  placeholder="Fullname"
                  name="fullname"
                  ref="fullname"
                  autocomplete="off"
                />
              </div>
              <div class="form-group">
                <input
                  class="
                    form-control form-control-solid
                    h-auto
                    py-7
                    px-6
                    rounded-lg
                    font-size-h6
                  "
                  type="email"
                  placeholder="Email"
                  name="email"
                  ref="remail"
                  autocomplete="off"
                />
              </div>
              <div class="form-group">
                <input
                  class="
                    form-control form-control-solid
                    h-auto
                    py-7
                    px-6
                    rounded-lg
                    font-size-h6
                  "
                  type="password"
                  placeholder="Password"
                  name="password"
                  ref="rpassword"
                  autocomplete="off"
                />
              </div>
              <div class="form-group">
                <input
                  class="
                    form-control form-control-solid
                    h-auto
                    py-7
                    px-6
                    rounded-lg
                    font-size-h6
                  "
                  type="password"
                  placeholder="Confirm password"
                  name="cpassword"
                  ref="cpassword"
                  autocomplete="off"
                />
              </div>
              <div class="form-group">
                <label class="checkbox mb-0">
                  <input type="checkbox" name="agree" />
                  <span class="mr-2"></span>
                  {{ $t("AUTH.REGISTER.AGREEMENT_TEXT") }}
                  <a href="#" class="ml-2">
                    {{ $t("AUTH.REGISTER.AGREEMENT_LINK") }} </a
                  >.
                </label>
              </div>
              <div class="form-group d-flex flex-wrap pb-lg-0 pb-3">
                <button
                  ref="kt_login_signup_submit"
                  class="
                    btn btn-primary
                    font-weight-bolder font-size-h6
                    px-8
                    py-4
                    my-3
                    mr-4
                  "
                  style="width: 150px"
                >
                  {{ $t("FORM.SUBMIT") }}
                </button>
                <button
                  type="button"
                  id="kt_login_signup_cancel"
                  class="
                    btn btn-light-primary
                    font-weight-bolder font-size-h6
                    px-8
                    py-4
                    my-3
                  "
                  @click="showForm('signin')"
                >
                  {{ $t("FORM.CANCEL") }}
                </button>
              </div>
            </form>
          </div>
          <!--end::Signup-->
          <!--begin::Forgot-->
          <div class="login-form login-forgot">
            <!--begin::Form-->
            <form
              class="form"
              novalidate="novalidate"
              id="kt_login_forgot_form"
              ref="kt_login_forgot_form"
            >
              <div class="pb-13 pt-lg-0 pt-5">
                <h3
                  class="
                    font-weight-bolder
                    text-dark
                    font-size-h4 font-size-h1-lg
                  "
                >
                  {{ $t("AUTH.FORGOT.TITLE") }}
                </h3>
                <p class="text-muted font-weight-bold font-size-h4">
                  {{ $t("AUTH.FORGOT.DESC") }}
                </p>
              </div>
              <div class="form-group">
                <input
                  class="
                    form-control form-control-solid
                    h-auto
                    py-7
                    px-6
                    rounded-lg
                    font-size-h6
                  "
                  type="email"
                  placeholder="Email"
                  name="email"
                  autocomplete="off"
                />
              </div>
              <div class="form-group d-flex flex-wrap pb-lg-0">
                <button
                  type="button"
                  id="kt_login_forgot_submit"
                  class="
                    btn btn-primary
                    font-weight-bolder font-size-h6
                    px-8
                    py-4
                    my-3
                    mr-4
                  "
                >
                  {{ $t("FORM.SUBMIT") }}
                </button>
                <button
                  type="button"
                  id="kt_login_forgot_cancel"
                  class="
                    btn btn-light-primary
                    font-weight-bolder font-size-h6
                    px-8
                    py-4
                    my-3
                  "
                  @click="showForm('signin')"
                >
                  {{ $t("FORM.CANCEL") }}
                </button>
              </div>
            </form>
          </div>
          <!--end::Forgot-->
        </div>
        <!--begin::Content footer-->
        <div
          class="
            d-flex
            justify-content-lg-start justify-content-center
            align-items-end
            py-7 py-lg-0
          "
        >
          <!--
          <a href="#" class="text-primary font-weight-bolder font-size-h5"
            >Terms</a
          >
          <a href="#" class="text-primary ml-10 font-weight-bolder font-size-h5"
            >Plans</a
          >
          <a href="#" class="text-primary ml-10 font-weight-bolder font-size-h5"
            >Contact Us</a
          >
          -->
        </div>
        <!--end::Content footer-->
      </div>
      <!--end::Content-->
    </div>
    <div class="mt-5">
      <b-modal
        v-model="showOtpForm"
        :title="$t('AUTH.LOGIN.OTP_VERIFICATION')"
        hide-footer
        @hidden="otpFormClosed"
        no-close-on-esc
        no-close-on-backdrop
      >
        <b-alert show variant="warning">
          <h3>
            <!-- <strong>{{ $t("AUTH.LOGIN.TWO_FA") }}</strong> -->
            <strong>{{ $t(otpFormTitle) }}</strong>
          </h3>
          <p>
            <!-- {{ $t("AUTH.LOGIN.TWO_FA_MESSAGE") }} -->
            {{ $t(otpFormMessage) }}
          </p>
        </b-alert>
        <b-form v-on:submit.prevent="otpCodeSubmit">
          <b-form-group id="input-group-1" label-for="input-1">
            <b-form-input
              id="input-1"
              v-model="form.otpCode"
              type="text"
              :placeholder="$t('AUTH.LOGIN.VERIFICATION_CODE')"
              autofocus
              required
            ></b-form-input>
          </b-form-group>
          <b-button
            type="submit"
            ref="otp_login_submit_btn"
            variant="primary"
            block
            >{{ $t("AUTH.LOGIN.LOGIN") }}</b-button
          >
          <b-button
            ref="otp_send_again_btn"
            variant="outline-primary"
            block
            @click="optSendAgain"
            >{{ $t("AUTH.LOGIN.SEND_AGAIN") }}</b-button
          >
        </b-form>
      </b-modal>
    </div>
    <b-overlay
      :show="showOverlay"
      class="overlay-background"
      variant="dark"
      no-wrap
    >
      <template #overlay>
        <div class="text-center">
          <b-icon
            icon="stopwatch"
            font-scale="8"
            variant="primary"
            animation="cylon"
            class="mb-4"
          ></b-icon>
          <h1 class="mt-5 pt-5 text-white">
            <p>{{ $t("AUTH.LOGIN.LOADING_MESSAGE_TITLE") }}</p>
            <p>{{ $t("AUTH.LOGIN.LOADING_MESSAGE_SUBTITLE") }}</p>
          </h1>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<!-- Load login custom page styles -->
<style lang="scss">
@import "@/assets/sass/pages/login/login-1.scss";
</style>

<script>
/* eslint-disable no-console */

import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
// FormValidation plugins
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";

import KTUtil from "@/assets/js/components/util";
import { mapGetters, mapState } from "vuex";
import { LOGOUT, REGISTER, OTP, OTP_AGAIN } from "@/store/modules/auth";
import Swal from "sweetalert2";
import { APP_LOGIN } from "../../store/modules/auth";

export default {
  name: "login-1",
  data() {
    return {
      showOtpForm: false,
      showOverlay: false,
      state: "signin",
      isGoogleTfaEnabled: false,
      identifier: null,
      password: null,
      form: {
        password: "",
        identifier: "",
        otpCode: ""
      }
    };
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    }),
    ...mapGetters(["activeUser"]),

    backgroundImage() {
      return (
        process.env.BASE_URL + "media/svg/illustrations/login-visual-2.svg"
      );
    },

    otpFormTitle() {
      const title = this.isGoogleTfaEnabled ? "TWO_FA_GOOGLE" : "TWO_FA";
      return "AUTH.LOGIN." + title;
    },
    otpFormMessage() {
      const message = this.isGoogleTfaEnabled
        ? "TWO_FA_GOOGLE_MESSAGE"
        : "TWO_FA_MESSAGE";
      return "AUTH.LOGIN." + message;
    }
  },
  mounted() {
    this.$store.dispatch(LOGOUT);
    const self = this;
    const signin_form = KTUtil.getById("kt_login_signin_form");
    const signup_form = KTUtil.getById("kt_login_signup_form");
    const forgot_form = KTUtil.getById("kt_login_forgot_form");

    self.fv = formValidation(signin_form, {
      fields: {
        identifier: {
          validators: {
            notEmpty: {
              message: this.$t("AUTH.VALIDATION.REQUIRED", {
                name: this.$t("FORM.PHONE_OR_EMAIL")
              })
            }
          }
        },
        password: {
          validators: {
            notEmpty: {
              message: this.$t("AUTH.VALIDATION.REQUIRED", {
                name: this.$t("FORM.PASSWORD")
              })
            }
          }
        }
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap()
      }
    });

    self.fv1 = formValidation(signup_form, {
      fields: {
        fullname: {
          validators: {
            notEmpty: {
              message: "Full name is required"
            }
          }
        },
        email: {
          validators: {
            notEmpty: {
              message: "Email is required"
            },
            emailAddress: {
              message: "The value is not a valid email address"
            }
          }
        },
        password: {
          validators: {
            notEmpty: {
              message: "Password is required"
            }
          }
        },
        cpassword: {
          validators: {
            notEmpty: {
              message: "Confirm password is required"
            },
            identical: {
              compare: function() {
                return signup_form.querySelector('[name="password"]').value;
              },
              message: "The password and its confirm are not the same"
            }
          }
        },
        agree: {
          validators: {
            notEmpty: {
              message: "You should agree terms and conditions"
            }
          }
        }
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap()
      }
    });

    self.fv2 = formValidation(forgot_form, {
      fields: {
        email: {
          validators: {
            notEmpty: {
              message: "Email is required"
            },
            emailAddress: {
              message: "The value is not a valid email address"
            }
          }
        }
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap()
      }
    });

    self.fv.on("core.form.valid", () => {
      this.identifier = this.form.identifier;
      this.password = this.form.password;

      // clear existing errors
      this.$store.dispatch(LOGOUT);

      // set spinner to submit button
      const submitButton = this.$refs["kt_login_signin_submit"];
      submitButton.disabled = true;
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      // send login request
      self.$store
        .dispatch(APP_LOGIN, {
          identifier: this.identifier,
          password: this.password
        })
        .then(isGoogleTfaEnabled => {
          this.isGoogleTfaEnabled = isGoogleTfaEnabled;
          self.showOtpForm = true;
        })
        .catch(error => {
          console.error("error:", error);

          const title = this.$t("FORM.ERROR_TITLE");
          const isInternalServerError = error.status === 500;

          let errorMessage = isInternalServerError
            ? this.$t("FORM.ERROR")
            : this.$t("AUTH.VALIDATION.INVALID_LOGIN");

          errorMessage += " " + this.$t("FORM.TRY_AGAIN");

          this.$root.$bvToast.toast(errorMessage, {
            title: title,
            variant: "danger",
            autoHideDelay: 5000
          });
        })
        .finally(() => {
          submitButton.disabled = false;
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
        });
    });

    this.fv.on("core.form.invalid", () => {
      Swal.fire({
        title: "",
        text: this.$t("AUTH.VALIDATION.INVALID_LOGIN"),
        icon: "error",
        confirmButtonClass: "btn btn-secondary",
        heightAuto: false
      });
    });

    this.fv1.on("core.form.valid", () => {
      const email = this.$refs.remail.value;
      const password = this.$refs.rpassword.value;

      // clear existing errors
      this.$store.dispatch(LOGOUT);

      // set spinner to submit button
      const submitButton = this.$refs["kt_login_signup_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      // dummy delay
      setTimeout(() => {
        // send register request
        this.$store
          .dispatch(REGISTER, {
            email: email,
            password: password
          })
          .then(() => this.$router.push({ name: "dashboard" }));

        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
      }, 2000);
    });

    this.fv1.on("core.form.invalid", () => {
      Swal.fire({
        title: "",
        text: "Please, provide correct data!",
        icon: "error",
        confirmButtonClass: "btn btn-secondary",
        heightAuto: false
      });
    });
  },
  methods: {
    showForm(form) {
      this.state = form;
      var form_name = "kt_login_" + form + "_form";
      KTUtil.animateClass(
        KTUtil.getById(form_name),
        "animate__animated animate__backInUp"
      );
    },
    redirectForgotPassword() {
      this.$router.push({ name: "forgot-password" });
    },
    otpCodeSubmit() {
      const self = this;
      const otpCode = self.form.otpCode;
      const credentials = { otpCode };

      if (this.isGoogleTfaEnabled) {
        credentials["identifier"] = self.identifier;
        credentials["password"] = self.password;
      }

      const submitButton = this.$refs["otp_login_submit_btn"];
      submitButton.disabled = true;
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      self.$store
        .dispatch(OTP, credentials)
        .then(() => {
          self.appLogin();
        })
        .catch(error => {
          const message = error?.data?.message || this.$t("FORM.ERROR");
          self.$bvToast.toast(message, {
            title: this.$t("FORM.ERROR_TITLE"),
            variant: "danger",
            autoHideDelay: 5000
          });
        })
        .finally(() => {
          submitButton.disabled = false;
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
        });
    },
    optSendAgain() {
      const self = this;

      const submitButton = this.$refs["otp_send_again_btn"];
      submitButton.disabled = true;
      submitButton.classList.add("spinner", "spinner-primary", "spinner-right");

      self.$store
        .dispatch(OTP_AGAIN)
        .then(() => {
          this.$bvToast.toast("Your sms code send again", {
            title: "Information",
            autoHideDelay: 5000
          });
        })
        .catch(error => {
          console.error("error:", error);
          const message = error?.data?.message || this.$t("FORM.ERROR");
          self.$bvToast.toast(message, {
            title: "Information",
            variant: "danger",
            autoHideDelay: 5000
          });
        })
        .finally(() => {
          submitButton.disabled = false;
          submitButton.classList.remove(
            "spinner",
            "spinner-primary",
            "spinner-right"
          );
        });
    },
    otpFormClosed() {
      this.showOverlay = true;
    },
    appLogin() {
      this.showOtpForm = false;
      this.showOverlay = false;
      window.location.href = "/";
    }
  }
};
</script>
