<template>
  <div>
    <b-overlay
      spinner-variant="danger"
      variant="dark"
      z-index="900"
      fixed
      :show="generatingPdf"
      no-wrap
    />
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="false"
      :preview-modal="true"
      filename="SalesReport"
      :pdf-quality="1"
      :manual-pagination="true"
      pdf-format="a4"
      pdf-orientation="portrait"
      @progress="onProgress"
      ref="html2Pdf"
    >
      <section slot="pdf-content" class="p-3">
        <sales-report-export :items="exportItems" />
      </section>
    </vue-html2pdf>

    <div class="card">
      <div class="card-body">
        <div class="d-flex justify-content-end mb-4">
          <b-button class="mr-3 mb-5" variant="info" @click="generatePDF">{{
            $t("PAGES.SALES_MANAGEMENT.LIST.EXPORT_TO_PDF")
          }}</b-button>
          <!-- <p>generatingPdf:{{generatingPdf}}</p> -->
          <JsonExcel
            v-for="mimeType in ['xls', 'csv']"
            :key="mimeType"
            :name="`SalesReport.${mimeType}`"
            :fetch="() => generateExcel(mimeType)"
            :fields="excelFields"
            :type="mimeType"
            :before-generate="
              () => {
                generatingPdf = true;
              }
            "
            :before-finish="
              () => {
                generatingPdf = false;
              }
            "
          >
            <b-button
              class="mr-3 mb-5"
              :variant="mimeType === 'xls' ? 'success' : 'primary'"
            >
              {{
                mimeType === "xls"
                  ? $t("PAGES.SALES_MANAGEMENT.LIST.EXPORT_TO_EXCEL")
                  : "CSV"
              }}
            </b-button>
          </JsonExcel>
        </div>

        <DatePickers
          :filters="filters"
          @on-start-date-selected="onStartDateSelected"
          @on-end-date-selected="onEndDateSelected"
          @on-all-dates-selected="onAllDatesSelected"
        >
          <template v-slot:filters>
            <list-fixed-filter-special
              :filter="fixedFilterSpecial"
              @on-fixed-filter-selected="onFixedFilterSelected"
            />
            <list-filter
              class="ml-4"
              v-for="(filter, filterIndex) in filters"
              :key="filterIndex"
              :filter="filter"
              :filter-index="filterIndex"
              @option-selected="onOptionSelected"
            />
          </template>
        </DatePickers>

        <ListSearch
          v-if="showSearch"
          :search-name="$t('PAGES.SALES_MANAGEMENT.LIST.LEVELS.MERCHANT')"
          @filter-change="onFilterChange"
        />

        <b-table
          ref="listTable"
          id="orders-table"
          primary-key="_id"
          :items="fetchData"
          :fields="fields"
          :filter="filter"
          :filter-included-fields="filterIncludedFields"
          selectable
          select-mode="single"
          :busy.sync="isBusy"
          :per-page="perPage"
          :current-page="currentPage"
          @row-clicked="onRowClicked"
          responsive
        >
          <template #table-busy>
            <div class="text-center text-danger my-5">
              <b-spinner class="align-middle busy-spinner"></b-spinner>
            </div>
          </template>

          <template #cell(nexusAmount)="data">
            {{ getPaidCryptoQuantity(data.item) }}
          </template>
          <template #cell(price)="data">
            <span class="row rowPriceText">
              {{ getPaymentUnitName(data.item) }}
              {{ data.value }}
            </span>
          </template>
          <template #cell(fiat)="data">
            {{ data.value ? "Fiat" : "Crypto" }}
          </template>
          <template #cell(selectedCurrencyCode)="data">
            {{ data.item.selectedCurrencyCode }}
          </template>
          <template #cell(platform)="data">
            {{ getPlatform(data.item) }}
          </template>

          <template #cell(fee)="data">
            <span class="d-inline-flex align-items-center">
              <b-icon-percent></b-icon-percent>{{ data.value }}
              <span
                v-b-popover.hover.top="
                  getCommissionPayerLabel(data.item.feeIncluded)
                "
              >
                ({{
                  getCommissionPayerLabel(data.item.feeIncluded).substring(
                    0,
                    1
                  )
                }})
              </span>
            </span>
          </template>

          <template #cell(commissionConvertCurrency)="data">
            <span class="d-inline-flex align-items-center">
              {{ data.value }}
              <b-icon
                icon="check"
                font-scale="2"
                class="common-icon-color"
                variant="success"
                v-if="data.item.commissionStatus"
              ></b-icon>
              <b-icon icon="x" v-else font-scale="2" class="text-dark"></b-icon>
            </span>
          </template>

          <template #cell(orderTotal)="data">
            <span class="row rowPriceText">
              {{ getPaymentUnitName(data.item) }}
              {{ data.value }}
            </span>
          </template>

          <template #cell(orderStatus)="data">
            <span
              class="status badge"
              :class="[getOrderBadgeAndLabel(data.item.status).itemClass]"
            >
              {{ getOrderBadgeAndLabel(data.item.status).itemLabel }}
            </span>
          </template>

          <template #cell(paymentStateName)="data">
            <b-form-select
              size="sm"
              :class="paymentStateClass(data.item.paymentStateId)"
              v-model="data.item.paymentStateId"
              value-field="id"
              @change="paymentStateUpdated(data.item)"
            >
              <b-form-select-option
                v-for="paymentState in PAYMENT_STATE"
                v-bind:key="paymentState.id"
                :value="paymentState.id"
              >
                {{
                  $t(
                    `PAGES.SALES_MANAGEMENT.LIST.PAYMENT_STATES[${paymentState.id}]`
                  )
                }}
              </b-form-select-option>
            </b-form-select>
            <!-- <p v-else>
              {{ $t(`PAGES.SALES_MANAGEMENT.LIST.PAYMENT_STATES[${data.item.paymentStateId}]`)}}
            </p> -->
          </template>
        </b-table>

        <div class="row align-items-center mt-5">
          <div class="mt-5 col-12 pt-5">
            <b-pagination
              align="fill"
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              aria-controls="orders-table"
            ></b-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import JsonExcel from "vue-json-excel";
import { mapGetters } from "vuex";
import VueHtml2pdf from "vue-html2pdf";
import { SET_BREADCRUMB } from "@/store/modules/breadcrumbs";
import Api from "@/services/api";
import { PAYMENT_STATE /* FILTER_TYPE */ } from "@/constants";
import ListSearch from "@/components/list/ListSearch.vue";
import ListFilter from "@/components/list/ListFilter.vue";
import ListFixedFilterSpecial from "@/components/list/ListFixedFilterSpecial.vue";
import DatePickers from "@/components/list/DatePickers.vue";
import { ListMixin } from "@/mixins/list.mixin";
import SalesReportExport from "@/components/list/SalesReportExport.vue";

export default {
  components: {
    JsonExcel,
    ListSearch,
    ListFilter,
    DatePickers,
    VueHtml2pdf,
    SalesReportExport,
    ListFixedFilterSpecial
  },
  mixins: [ListMixin],
  data() {
    return {
      PAYMENT_STATE: PAYMENT_STATE,
      filterIncludedFields: ["merchant.title"],
      generatingPdf: false,
      exportItems: [],
      fixedFilters: "",
      showSearch: true,
      filters: [
        /* {
          text: "PAGES.SALES_MANAGEMENT.LIST.PAYMENT_STATE",
          field: "paymentState.id",
          type: FILTER_TYPE.PAYMENT_STATE,
          currentValue: undefined
        }, */
        /* {
          text: "Fiat",
          field: "fiat",
          type: FILTER_TYPE.FIAT,
          currentValue: undefined
        } */
      ],
      fields: [
        {
          key: "_id",
          label: "ID",
          sortable: true
        },
        {
          key: "merchantName",
          label: this.$t("PAGES.SALES_MANAGEMENT.LIST.LEVELS.MERCHANT"),
          sortable: true
        },
        {
          key: "branchTitle",
          label: this.$t("PAGES.SALES_MANAGEMENT.LIST.LEVELS.BRANCH")
        },
        {
          key: "selectedCurrencyCode",
          label: this.$t("PAGES.DASHBOARD.CRYPTO"),
          class: "text-center"
        },
        {
          key: "amount",
          label: this.$t("PAGES.SALES_MANAGEMENT.LIST.CRYPTO_QUANTITY"),
          class: "text-center"
        },
        {
          key: "nexusAmount",
          label: this.$t("PAGES.SALES_MANAGEMENT.LIST.CRYPTO_QUANTITY_PAID"),
          class: "text-center"
        },
        {
          key: "price",
          label: this.$t("PAGES.SALES_MANAGEMENT.LIST.PRICE"),
          sortable: true,
          class: "text-right"
        },
        {
          key: "fee",
          label: this.$t("PAGES.SALES_MANAGEMENT.LIST.COMMISSION"),
          sortable: true,
          class: "text-center"
        },
        {
          key: "commissionConvertCurrency",
          label: this.$t("PAGES.SALES_MANAGEMENT.LIST.SERVICE_COMMISSION"),
          sortable: true,
          class: "text-center"
        },
        {
          key: "orderTotal",
          label: this.$t("PAGES.SALES_MANAGEMENT.LIST.TOTAL"),
          class: "text-right"
        },
        /* {
          key: "fiat",
          label: "Fiat"
        }, */
        {
          key: "createdAt",
          label: this.$t("PAGES.SALES_MANAGEMENT.LIST.DATE_TIME"),
          class: "text-center",
          sortable: true,
          formatter: value => {
            return this.$dateTimeFilter(value, "date-time");
          }
        },
        {
          key: "platform",
          label: this.$t("PAGES.SALES_MANAGEMENT.LIST.CUSTOMER"),
          class: "text-center",
          sortable: true
        },
        {
          key: "orderStatus",
          label: this.$t("PAGES.SALES_MANAGEMENT.LIST.STATUS"),
          sortable: true,
          class: "text-center"
        }
        /* {
          key: "paymentStateName",
          label: this.$t("PAGES.SALES_MANAGEMENT.LIST.PAYMENT_STATE"),
          class: "text-center",
          sortable: true
        } */
      ],
      excelFields: {},
      excelType: "xls",
      fixedFilterSpecial: {
        name: null,
        selectedValue: null,
        options: []
      }
    };
  },
  mounted() {
    this.onMounted(this.$route.name);
  },
  computed: {
    ...mapGetters({
      mainCompanyId: "orders/MAIN_COMPANY_ID"
    }),
    ...mapGetters(["activeUserId"]),
    fetchUrl() {
      return "company-orders";
    },
    isRefundedPage() {
      return this.$route.name === "refunded-order-list";
    }
  },
  methods: {
    // async fetchOrdersTotals() {
    //   const ordersTotals = await this.$store.dispatch(
    //     "orders/GET_ORDERS_TOTAL",
    //     this.mainCompanyId
    //   );

    //   this.ordersTotal = ordersTotals.ordersTotal;
    //   this.ordersCount = ordersTotals.ordersCount;
    // },
    async paymentStateUpdated(order) {
      const paymentStateId = order.paymentStateId;

      let updateOrder = {
        orderId: order._id,
        paymentStateId: paymentStateId
      };

      const updateResult = await Api.post(
        "/company-orders/update-payment-state",
        updateOrder
      );
      order = updateResult.data;

      const successTitle = this.$t("FORM.SUCCESS_TITLE");
      const successMessage = this.$t(
        "PAGES.SALES_MANAGEMENT.LIST.PAYMENT_STATE_UPDATED"
      );

      this.$root.$bvToast.toast(successMessage, {
        title: successTitle,
        variant: "success",
        autoHideDelay: 5000
      });
    },
    onRowClicked(item) {
      /* this.$router.push({
        name: "order-details",
        params: { orderId: item._id || item.id }
      }); */
      const orderDetailsRoute = this.$router.resolve({
        name: "order-details",
        params: { orderId: item._id || item.id }
      });
      window.open(orderDetailsRoute.href, "_blank");
    },
    onProgress(event) {
      this.generatingPdf = event < 100;
    },
    onMounted(routeName) {
      let breadCrumbTitle = this.$t("ROUTES.ALL_SALES");
      let fixedFilter = "status[]=1&status[]=13&status[]=5";
      if (routeName === "refunded-order-list") {
        breadCrumbTitle = this.$t("ROUTES.REFUNDED_TRANSACTIONS_LIST");
        fixedFilter = "status=6";
      }

      this.$store.dispatch(SET_BREADCRUMB, [
        { title: this.$t("ROUTES.SALES_MANAGEMENT") },
        { title: breadCrumbTitle }
      ]);
      this.fixedFilters = fixedFilter;
      if (this.activeUserId) {
        this.fixedFilters = `${this.fixedFilters}&responsibleAdminId=${this.activeUserId}`;
      }
      this.fixedFilterSpecial = this.getFixedFilterSpecial(routeName);
      this.$refs.listTable.refresh();
    },
    onFixedFilterSelected(value) {
      this.fixedFilters = value;
      this.fixedFilterSpecial.selectedValue = value;
      if (this.activeUserId) {
        this.fixedFilters = `${this.fixedFilters}&responsibleAdminId=${this.activeUserId}`;
      }
      this.listTable.refresh();
    },
    getFixedFilterSpecial(routeName) {
      const isRefund = routeName === "refunded-order-list";
      return {
        name: this.$t("PAGES.SALES_MANAGEMENT.LIST.STATUS"),
        selectedValue: isRefund
          ? "status=6"
          : "status[]=1&status[]=13&status[]=5",
        options: [
          {
            label: this.$t("PAGES.SALES_MANAGEMENT.LIST.ORDER_STATUSES.1"),
            value: "status[]=1&status[]=13"
          },
          {
            label: this.$t(
              `PAGES.SALES_MANAGEMENT.LIST.ORDER_STATUSES.${
                isRefund ? "6" : "5"
              }`
            ),
            value: `status=${isRefund ? "6" : "5"}`
          },
          {
            label: this.$t("ECOMMERCE.COMMON.ALL"),
            value: `status[]=1&status[]=13&status[]=${isRefund ? "6" : "5"}`
          }
        ]
      };
    },
    getCommissionPayerLabel(value) {
      return value
        ? this.$t("COMPANY.BRANCH")
        : this.$t("PAGES.SALES_MANAGEMENT.LIST.CUSTOMER");
    }
  },
  watch: {
    $route: function(newVal, oldVal) {
      if (newVal.name !== oldVal.name) {
        this.onMounted(newVal.name);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.payment-button {
  margin: 0;
  padding: 0;
  background-color: transparent;
  border: none;

  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
}

.busy-spinner {
  width: 3rem;
  height: 3rem;
  margin: 5rem 0;
}

.status {
  height: calc(1.35em + 1.1rem + 2px);
  display: inline-flex;
  align-items: center;
}

.rowPriceText {
  overflow-x: auto;
  white-space: nowrap;
}
</style>
