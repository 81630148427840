// USA
export const locale = {
  TRANSLATOR: {
    SELECT: "Dil Seçin"
  },
  SLOGAN: "Ödeme Almanın Yeni Yolu",
  MENU: {
    NEW: "Yeni",
    EDIT: "Düzenle",
    ACTIONS: "İşlemler",
    CREATE_POST: "Yeni yazı oluştur",
    PAGES: "Sayfalar",
    FEATURES: "Özellikler",
    APPS: "Uygulamalar",
    GREET: "Merhaba",
    BACK: "Geri"
  },
  ROUTES: {
    DASHBOARD: "Genel Görünüm",
    HOME: "Ana Sayfa",
    SALES_MANAGEMENT: "Satış Yönetimi",
    ALL_SALES: "Tüm Satışlar",
    NEW_SALE: "Yeni Satış",
    QR_SALE: "QR Kod İle Satış Oluştur",
    SALE_DETAILS: "Satış Detayı",
    WALLET_MANAGEMENT: "Cüzdan Yönetimi",
    WALLETS: "Cüzdanlar",
    WITHDRAWALS: "Para Çekme İşlemleri",
    PERSONNEL_MANAGEMENT: "Persnnel Yönetimi",
    PERSONNEL: "Tüm Persnnel",
    NEW_PERSONNEL: "Yeni Personel",
    USER_MANAGEMENT: "Kullanıcı Yönetimi",
    USERS: "Kullanıcılar",
    NEW_USER: "Yeni Kullanıcı",
    DELETED_USERS: "Silinmiş Kullanıcılar",
    PROFILE: "Profil",
    COMPANY: "Firma Ayarları",
    SETTINGS: "Genel Ayarlar",
    ROLES: "Roller",
    LOGS: "Loglar",
    LOGOUT: "Çıkış",
    CHANGE_PASSWORD: "Şifre Değiştir",
    CORPORATE: "Kurumsal",
    SALES_REPRESENTATIVES: "Satış Temsilcileri",
    NEW_REPRESENTATIVE: "Yeni Temsilci",
    COMPANIES: "Firmalar",
    BRANCHES: "Şubeler",
    BRANCH: "Şube",
    MEMBER_MERCHANTS: "Üye İşyerleri",
    TRANSACTIONS: "İşlemler",
    TRANSACTIONS_LIST: "İşlem Listesi",
    POS_LIST: "El Terminali Listesi",
    REFUNDED_TRANSACTIONS_LIST: "İade Edilmiş İşlemler Listesi",
    WALLET: "Cüzdan",
    KYC_KYB_REQUESTS: "KYC-KYB İstekleri",
    NEW_WALLET: "Yeni Cüzdan",
    DISTS: "Distribütörler"
  },
  FORM: {
    ID: "Id",
    YES: "Evet",
    NO: "Hayır",
    OK: "Tamam",
    TRY_AGAIN: "Lütfen tekrar deneyin.",
    SAVE: "Kaydet",
    UPDATE: "Güncelle",
    SUBMIT: "Gönder",
    CANCEL: "İptal",
    CONFIRM: "Onayla",
    EMAIL: "E-Posta",
    FIRST_NAME: "Ad",
    LAST_NAME: "Soyad",
    FULL_NAME: "Ad soyad",
    PASSWORD: "Parola",
    PASSWORD_CONFIRM: "Parola Tekrar",
    PHONE: "Telefon",
    LOGIN_WITH_GOOGLE: "Google İki Adımlı Doğrulama İle Giriş Yapın",
    GOOGLE_AUTHENTICATOR: "Google Authenticator",
    GOOGLE_SCAN_MESSAGE1: "Karekodu",
    GOOGLE_SCAN_MESSAGE2: "ile tarayın",
    APP: "app",
    CLICK: "Tıklayın",
    WHEN_DONE: "Onaylandığında",
    PHONE_OR_EMAIL: "Telefon veya Eposta",
    CONFIRM_PASSWORD: "Parolayı Doğrulayın",
    USERNAME: "Kullanıcı adı",
    SUCCESS_TITLE: "İşlem Başarılı",
    SUBMITTED: " başarıyla kaydedildi",
    UPDATED: " başarıyla güncellendi",
    DELETED: " başarıyla silindi",
    ERROR_TITLE: "Hata!",
    ERROR: "Bir Hata Oluştu.",
    PLEASE_SELECT: "Seçiniz",
    PAYMENT_UNIT: "Ödeme Birimi",
    POS_NUMBER: "El Terminali Numarası",
    SELECT_A_PAYMENT_UNIT: "Ödeme birimi seçiniz",
    SELECT_A_CURRENCY_UNIT: "Para Birimini Seçiniz",
    ASSIGNMENT_CONFIRMATION: "Aktarma Onayı",
    MUCH_OPTIONS_SELECTED: "{value} seçenek seçildi.",
    UNASSIGNMENT_CONFIRMATION: "Kaldırma Onayı",
    ID_NO: "Kimlik Numarası",
    SIGNATURE_AUTH: "İmza Yetkilileri",
    ADD_ROW: "Satır Ekle"
  },
  AUTH: {
    GENERAL: {
      OR: "Ya da",
      SUBMIT_BUTTON: "Gönder",
      NO_ACCOUNT: "Hesabınız yok mu?",
      SIGNUP_BUTTON: "Kayıt ol",
      FORGOT_BUTTON: "Parolamı Unuttum",
      BACK_BUTTON: "Geri",
      PRIVACY: "Gizlilik",
      LEGAL: "Yasal",
      CONTACT: "İletişim"
    },
    LOGIN: {
      TITLE: "Giriş",
      BUTTON: "Giriş",
      WELCOME: "Hoş Geldiniz",
      NEW_HERE: "Yeni misiniz?",
      CREATE_ACCOUNT: "Yeni Hesap Oluştur",
      LOGIN: "Giriş Yap",
      OTP_VERIFICATION: "OTP Doğrulaması",
      TWO_FA: "2 Aşamalı Doğrulama",
      TWO_FA_GOOGLE: "Google ile 2 Aşamalı Doğrulama",
      TWO_FA_MESSAGE:
        "Lütfen giriş yapmak için telefonunuza ya da elektronik posta adresinize gelen doğrulama kodunu giriniz",
      TWO_FA_GOOGLE_MESSAGE:
        "Lütfen giriş yapmak için Google Authentıcator uygulamasından alacağınız doğrulama kodunu giriniz",
      VERIFICATION_CODE: "Doğrulama Kodunu Giriniz",
      SEND_AGAIN: "Tekrar Gönder",
      LOADING_MESSAGE_TITLE: "Biraz Bekleteceğiz",
      LOADING_MESSAGE_SUBTITLE: "Bilgilerinizi Çekiyoruz..."
    },
    FORGOT: {
      TITLE: "Parolanızı mı unuttunuz?",
      DESC: "Parolanızı sıfırlamak için e-posta adresinizi giriniz",
      SUCCESS: "Hesabınız başarıyla sıfırlandı."
    },
    REGISTER: {
      TITLE: "Kayıt ol",
      DESC: "Yeni hesap oluşturmak için bilgilerinizi giriniz",
      AGREEMENT_TEXT: "kabul ediyorum",
      AGREEMENT_LINK: "Şartları ve Koşulları",
      SUCCESS: "Hesabınız başarıyla kaydedildi."
    },
    VALIDATION: {
      INVALID: "{name} geçersiz",
      REQUIRED: "{name} gerekli alandır",
      MIN_LENGTH: "{name} alanı en az {min} karakter uzunluğunda olmalıdır.",
      AGREEMENT_REQUIRED: "Şartlar ve Koşulların kabul edilmesi gerekmektedir",
      NOT_FOUND: "İstenen {name} bulunamadı",
      INVALID_LOGIN: "Giriş bilgisi yanlıştır",
      REQUIRED_FIELD: "Zorunlu alan",
      MIN_LENGTH_FIELD: "En az karakter:",
      MAX_LENGTH_FIELD: "En fazla karakter:",
      INVALID_FIELD: "Alan geçersiz",
      PASSWORD_DONT_MATCH: "Şifreler uyuşmuyor."
    }
  },
  LIST: {
    LEVEL: "Seviye",
    CREATED_AT: "Kayıt",
    UPDATED_AT: "Son güncelleme"
  },
  COMPANY: {
    COMPANY: "Firma",
    COMPANY_INFO: "Firma Bilgileri",
    BRANCH: "Şube",
    SALES_REPRESENTATIVE: "Satış Temsilcisi",
    USER: "Kullanıcı",
    TITLE: "Ünvan",
    TAX_ADMINISTRATION: "Vergi Dairesi",
    TAX_CITY: "Vergi Dairesi Şehir",
    TAX_NUMBER: "Vergi Numarası",
    QUICK_SALE: "Hızlı Satış",
    MAIL_INFO_MESSAGE:
      "Açılan Kullanıcı kimlik bilgileri bu adrese gönderilecektir.",
    domestic_transfer_infos: "Yurtiçi Transfer Bilgileri",
    overseas_transfer_infos: "Yurtdışı Transfer Bilgileri",
    bank: "Banka",
    account_name: "Hesap Adı",
    account_type: "Hesap Tipi",
    account_no: "Hesap Numarası",
    buyer: "Alıcı Bilgisi(Kişi ya da Firma Adı)",
    city: "Şehir",
    swift_code: "Swift Kodu",
    update_payment_info: "Ödeme Bilgilerini Güncelle",
    parentTitle: "Üst Firma Ünvan",
    dist_info: "Distribütör Bilgileri",
    merchant_info: "Bayi Bilgileri",
    branch_info: "Şube Bilgileri",
    affiliated_merchants: "Bağlı Bayiler",
    affiliated_branches: "Bağlı Şubeler",
    register_new_dist: "Yeni Distribütör Ekle",
    register_new_merch: "Yeni Bayi Ekle",
    register_new_branch: "Yeni Şube Ekle"
  },
  PAGES: {
    SALES_MANAGEMENT: {
      ORDER_DETAILS: {
        SALE_DETAILS: "Satış Detayları",
        DELETE_ORDER: "Satışı Sil",
        ORDER_INFOS: "Sipariş Bilgileri",
        ORDER_ID: "Sipariş Kimliği",
        REFUND_WALLET_ADDRESS: "İade Yapılacak Cüzdan Adresi",
        UPLOAD_BANK_RECEIPT: "Dekont Yükle",
        DOWNLOAD_BANK_RECEIPT: "Dekont İndir"
      },
      LINK_SALES: {
        NEW_SALE: "Link İle Satış Yap",
        ORDER_INFO: "Sipariş Bilgileri",
        ORDER_AMOUNT: "Sipariş Tutarı",
        COMMISSION_RATE: "Komisyon Oranı",
        DEDUCT_COMMISSION: "Komisyon oranı sipariş tutarından düşülsün mü?",
        COMMISSION_AMOUNT: "Komisyon Tutarı",
        ORDER_TOTAL: "Toplam Sirapiş Tutarı",
        CREATE_LINK: "Link Oluştur",
        ORDER_IDENTITY: "Sipariş Kimliği",
        TOTAL_AMOUNT: "Toplam Tutar",
        QUANTITY: "Adet",
        TOTAL_QUANTITY: "Toplam Miktar",
        WALLET: "Cüzdan Adresi",
        TRANSACTION_STATUS: "İşlem Durumu",
        TRANSACTION_SUCCESS: "İşlem Başarılı",
        TRANSACTION_ERROR: "İşlem Başarısız"
      },
      LIST: {
        ORDER_ID: "Sipariş No",
        PRICE: "Fiyat",
        COMMISSION: "Komisyon",
        TOTAL: "Toplam",
        STATUS: "Durum",
        DATE: "Tarih",
        SALE: "Satış",
        PENDING: "Beklemede",
        COMPLETED: "Tamamlandı",
        PROCESSING: "İşleniyor",
        FAILED: "İşlenemedi",
        CANCELLED: "İptal Edildi",
        NOT_ENOUGH: "Eksik Ödeme",
        ALL_DATES: "Tüm tarihler",
        SELECT_START_DATE: "Başlangıç tarihini seçin",
        SELECT_END_DATE: "Bitiş tarihini seçin",
        EXPORT_TO_PDF: "PDF",
        EXPORT_TO_EXCEL: "EXCEL",
        PLATFORM: "Platform",
        PAYMENT_STATE: "Bayi Ödemesi",
        PAYMENT: "Ödeme",
        LEVELS: {
          DISTRIBUTOR: "Distribütör",
          MERCHANT: "Bayi",
          BRANCH: "Şube"
        },
        PAYMENT_STATES: {
          1: "Beklemede",
          2: "Yapılmakta",
          3: "Tamamlandı",
          4: "İptal edildi"
        },
        ORDER_STATUSES: {
          0: "Beklemede",
          1: "Tamamlandı",
          2: "Yapılmakta",
          3: "Başarısız",
          4: "İptal edildi",
          5: "Yetersiz bakiye",
          6: "İade",
          7: "Gönderildi",
          8: "İşlem alındı",
          9: "Kuyrukta",
          10: "Yetkilendirme bekleniyor",
          11: "Reddedildi",
          12: "Engellendi",
          13: "Eksik ödeme tamamlandı"
        },
        PAYMENT_STATE_UPDATED: "Ödeme durumu güncellendi",
        AFFILIATED_COMPANIES: "Bağlı Firmalar",
        CRYPTO_QUANTITY: "Kripto Miktarı",
        CRYPTO_QUANTITY_PAID: "Ödenen Kripto Miktarı",
        CUSTOMER: "Müşteri",
        SERVICE_COMMISSION: "Hizmet Komisyonu",
        DATE_TIME: "Tarih - Saat"
      },
      POS: {
        POS: "El Terminali",
        SERIAL: "Seri No",
        SYSTEM_DEFINED: "Sistem tanımlı",
        POS_SETUP: "El Terminali Ayarları",
        DELIVERED: "Teslimat",
        HAS_DELIVERED: "Teslim edildi mi?",
        REASON: "Sebep",
        UNDELIVERED_REASON: "Teslim edilmeme sebebi",
        DELIVERY_FORM: "Teslim formu",
        PASS: "Geçiş",
        POS_DESCRIPTION1: "El Terminali Tanım 1",
        POS_DESCRIPTION2: "El Terminali Tanım 2",
        SELECTED_POS_COUNT: "Seçilen El Terminali:",
        ASSIGN_TO_DISTRIBUTOR: "Distribütore Aktar"
      },
      QR_SALE: {
        NEW_SALE_WITH_QR_CODE: {
          TITLE: "QR Kod İle Satış Yap",
          CUSTOMER_INFO: "Müşteri Bilgileri",
          SUCCESS_TITLE: "Ödemeniz Alınmıştır.",
          SUCCESS_SUBTITLE: "Teşekkür ederiz.",
          ERROR_SUBTITLE: "Lütfen tekrar deneyın.",
          SCAN_QR_CODE: "QR Kod Tarama",
          MIRACLE_CUSTOMER: "Miracle Müşterisi",
          NOT_MIRACLE_CUSTOMER: "Miracle Müşt. Değil",
          AN_ERROR_HAS_OCCURRED: "Bir hata oluştu.",
          TRY_AGAIN: "Tekrar Dene",
          ALL_ORDERS: "Tüm Siparişler",
          NEW_ORDER: "Yeni Satış Oluştur"
        },
        ORDER_COMPLETED: "Ödeme alındı.",
        ORDER_AMOUNT_IS: "İşlem Tutarı",
        MISSING_ORDER_PAYMENT: "Eksik ödeme yapıldı.",
        ORDER_CRYPTO_AMOUNT_IS: "Kripto Tutarı"
      },
      PROFILE: {
        PROFILE_PAGE: "Profil Sayfası"
      }
    },
    DASHBOARD: {
      CRYPTO: "Kripto",
      CURRENCY: "Para Birimi",
      LATEST_TRANSACTIONS: "Son İşlemler",
      CRYPTO_AMOUNT: "Tutar",
      MONTHLY_ORDER_STATUS: "Aylık Sipariş Durumu",
      CURRENCY_TYPE: "Döviz Cinsi",
      TOTAL_TRANSACTION_AMOUNT: "Toplam İşlem Tutarı",
      AMOUNT_PAID: "Ödenmiş Tutar",
      AMOUNT_AWAITING_PAYMENT: "Ödenmesi Bekleyen Tutar"
    }
  },
  ECOMMERCE: {
    COMMON: {
      SELECTED_RECORDS_COUNT: "Seçili kayıt adedi: ",
      ALL: "HEPSİ",
      SUSPENDED: "Durduruldu",
      ACTIVE: "Aktif",
      FILTER: "Filtre",
      BY_STATUS: "Duruma göre",
      BY_TYPE: "Tipe göre",
      BUSINESS: "İş",
      INDIVIDUAL: "Bireysel",
      SEARCH: "Arama",
      IN_ALL_FIELDS: "tüm alanlarda"
    },
    ECOMMERCE: "eTicaret",
    CUSTOMERS: {
      CUSTOMERS: "Müşteriler",
      CUSTOMERS_LIST: "Müşteri List",
      NEW_CUSTOMER: "Yeni Müşteri",
      DELETE_CUSTOMER_SIMPLE: {
        TITLE: "Müşteriyi Sil",
        DESCRIPTION: "Bu müşteriyi kalıcı olarak silmek istiyor musunuz?",
        WAIT_DESCRIPTION: "Müşteri siliniyor...",
        MESSAGE: "Müşteri silindi"
      },
      DELETE_CUSTOMER_MULTY: {
        TITLE: "Müşterileri Sil",
        DESCRIPTION: "Seçili müşterileri kalıcı olarak silmek istiyor musunuz?",
        WAIT_DESCRIPTION: "Müşteriler siliniyor...",
        MESSAGE: "Seçili müşteriler silindi"
      },
      UPDATE_STATUS: {
        TITLE: "Seçili müsteriler için durum güncellendi",
        MESSAGE: "Seçili müşterilerin durumları başarıyla güncellendi"
      },
      EDIT: {
        UPDATE_MESSAGE: "Müşteri güncellendi",
        ADD_MESSAGE: "Müşteri kaydı oluşturuldu"
      }
    }
  },
  VALIDATION: {
    NUMERIC: "Sadece numerik değerler kabul edilir.",
    MIN_VALUE: "{name} minimum değer {min} olmalı",
    ONLY_INTEGERS: "Yalnızca tamsayılar geçerlidir.",
    MAX_LENGTH: "En fazla {num} karakterden oluşabilir.",
    REQUIRED_FIELD: "Zorunlu alan",
    EMAIL_NOT_VALID: "Geçersiz email adresi.",
    DUPLICATED_VALUES: "Aynı {field} birden fazla defa girildi.",
    FILE_SIZE_LESS_THAN: "Dosya boyutu {size}'tan küçük olmalıdır.",
    ACCEPTED_FILE_TYPES: "Kabul edilen dosya türleri; {types}"
  },
  COMMON: {
    ACTIVATE: "Aktif Et",
    DEACTIVATE: "Pasif Et",
    NO_DATA_AVAILABLE: "Veri bulunamadı.",
    INFORM: "Bilgilendirme",
    ACCESS: "Erişim",
    GRANT_ACCESS: "Erişim İzni Ver",
    RESTRICT_ACCESS: "Erişimi Kısıtla",
    payment: "Ödeme",
    remove: "Kaldır",
    file_drop_zone_label:
      "Dosya yüklemek için dosyayı buraya sürükleyin ya da <u>tıklayın.</u>",
    file_drop_zone_label_dragging: "Yüklemek için bırakın.",
    hide_low_balances: "Düşük Bakiyeleri Gizle",
    refresh: "Yenile",
    show_file: "Dosya Göster",
    reject: "Reddet",
    inspect: "Sorgula",
    can_select_multi: "Çoklu seçim yapabilirsiniz.",
    multi_select_limit_text: "ve {count} seçilen"
  },
  devices: {
    pos: "Pos",
    vp: "Sanal Pos",
    pnl: "Panel",
    wc: "WooCommerce"
  },
  kyc_kyb: {
    idCard: "Kimlik Kartı",
    commerceNumber: "Ticaret Odası Numarası",
    taxNumber: "Vergi Numarası",
    sourceOfFund: "Fon kaynağı",
    files_companyActivities:
      "Şirket faaliyet listesi ve faaliyet gösterilen ülkelerin listesi",
    files_orgChart: "Tüm kuruluşlar için organizasyon şeması",
    files_ubo: "Nihai Yararlanıcı Kayıt Belgesi",
    submitted: "İsteğiniz başarıyla gönderildi.",
    waiting: "Onay Bekliyor",
    uploaded: "Yüklendi",
    approved: "Onaylandı",
    denied: "Reddedildi",
    kyc_kyc_details: "KYC-KYB Detayları",
    status: "Durum",
    revoke_confirmation: "Onayı geri al",
    id_cards_including_ubos:
      "Direktörler ve Ortakların Kimlik Kartları (Nihai Yararlanıcılar dahil)"
  },
  wallets: {
    walletCode: "Cüzdan Kodu",
    currencyName: "Kripto Para Adı",
    currencyCode: "Kripto Para Kodu",
    currencyIcon: "Kripto Para İkonu (CDN)",
    selectCrypto: "Kripto Para Seçiniz",
    assign_to_branch: "Şubede Aktifleştir",
    please_select_branches_first: "Lütfen önce Şube/Şubeler seçiniz.",
    selectedWallets: "Seçilen Cüzdanlar",
    assignConfirmWarning:
      "Seçilen Kripto cüzdanları şubede/şubelerde aktifleştirilecektir, emin misiniz?",
    unassign_to_branch: "Şubede Pasifleştir",
    unassignConfirmWarning:
      "Seçilen Kripto cüzdanları şubede/şubelerde pasifleştirilecektir, emin misiniz?",
    select_all: "TÜMÜNÜ SEÇ",
    unselect_all: "TÜM SEÇİLENLERİ KALDIR"
  }
};
